.footer {
  background: #131a23;
  display: flex;
  width: 100%;
  height: 5vh;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 50px;
  gap: 10px;
}

.footer > p {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin: 0;
}
