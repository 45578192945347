.anonimous-page-container {
  background-color: #000a18;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  width: 100%;
}
.anonimous-page-body {
  flex: 1;
}
