@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.generic-sidebar-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.generic-sidebar-navbar {
  display: flex;
  width: 100%;
  padding: 10px 100px;
  align-items: center;
  gap: 87px;
  border: 1px solid var(--Gray-5, #e0e0e0);
  background: var(--blanco, #fff);
}

.generic-sidebar-arrow {
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 15px;
}

.sidebar-image {
  display: flex;
  width: 114px;
  height: 28px;
  flex-shrink: 0;
  background: #ffc857;
}

.sidebar-image img {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
}

.title-sidebar {
  width: 825px;
  flex-shrink: 0;
}

.text-title-sidebar {
  color: var(--A-oscuro, #002e2c);
  font-family: Roboto Slab;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.text-title-sidebar span {
  color: var(--A-oscuro, #002e2c);
  font-family: Roboto Slab;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.generic-sidebar {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.right-render {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.left-sidebar {
  width: 25vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--blanco, #fff);
  flex-shrink: 0;
  user-select: none;
  position: relative;
  transition: translate 0.5s ease;
}

.open-left-sidebar {
  translate: 0%;
  display: flex;
}
.closed-left-sidebar {
  translate: -100%;
  display: none;
}

.sidebar-fade {
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease, display 0.3s ease;
}

.left-sidebar-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5%;
  gap: 8px;
  flex-shrink: 0;
}

.left-sidebar-top-user {
  flex: 1;
  color: var(--A-oscuro, #002e2c);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.left-sidebar-top-arrow-icon {
  flex: 0;
}

.left-sidebar-middle {
  flex: 1;
  width: 100%;
  height: 100%;
}

.left-sidebar-bottom {
  display: block;
  width: 100%;
}
.left-sidebar-bottom-logout {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 15%;
}
.left-sidebar-bottom-logout a {
  cursor: pointer;
  color: var(--A-oscuro, #002e2c);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.left-sidebar-bottom-by-datafy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 10% 5% 10%;
}

.left-sidebar-bottom-by-datafy span {
  color: var(--Gray-4, #bdbdbd);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
