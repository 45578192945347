.home-container {
  display: flex;
  flex-direction: column;
}

.home-top-container {
  display: flex;
  flex-direction: row;
  padding: 3% 12%;
  gap: 1vw;
}

.home-historical-price-container {
  width: 50vw;
}

.home-news-price-list-container {
  width: 25vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (width <= 1200px) {
  .home-container {
    display: flex;
    flex-direction: column-reverse;
  }
  .home-top-container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .home-historical-price-container {
    width: 100%;
  }

  .home-news-price-list-container {
    width: 100%;
  }

  .home-bottom-container {
    width: 100%;
  }
}
