.chat-window {
    border-top-left-radius: 10px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 10px 0 10px;
    width: 500px;
    height: 600px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 99;
}

.conversation-panel {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 2em;
    margin-bottom: 3em;
}

.input-panel {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
}

.input-panel button {
    height: 100%; /* ocupa todo el alto del contenedor */
    padding: 3px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.input-panel input[type='text'] {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    overflow-x: auto;
}

.hide-chat {
    position: absolute;
    opacity: 50%;
    top: 10px;
    right: 10px;
    background-color: #ff3737;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.hide-chat:hover {
    opacity: 100%;
    background-color: #e61919;
}

.clean-chat {
    float: left;
    width: 100px;
    opacity: 50%;
    top: 10px;
    right: 10px;
    background-color: #87ceeb;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.clean-chat:hover {
    opacity: 100%;
    background-color: #64b5f6;
}

.toggle-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.toggle-chat:hover {
    background-color: #3e8e41;
}
