.nav-bar {
  background-color: #000a18;
  padding: 10px 40px;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-bar-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-bar-left > .title > h1 {
  color: #ffd60a;
  cursor: pointer;
  font-family: 'Roboto', Helvetica;
  font-size: 32px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.nav-bar-left > .title > h1 > span {
  font-weight: 800;
}

.nav-bar-items {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  padding: 0 10px 0 0;
  position: relative;
  margin-left: 10px;
}
.nav-bar-items > .item {
  cursor: pointer;
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
}
.nav-bar-items > .item > p {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.nav-bar-items > div > .item-active {
  font-weight: bold;
}

.nav-bar-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.nav-bar-right > div {
  cursor: pointer;
}

.nav-bar-user {
  height: 40px;
}

.login-sign-up-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.log-in {
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 5px;
}

.log-in > p {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.sign-up {
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 5px;
  border-radius: 8px;
  border: solid 1px #ffd60a;
  background-color: #ffd60a;
}
.sign-up > p {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #000814;
}

.dropdown-top-arrow {
  position: absolute;
  transform: translateX(-55%);
  z-index: 2;
}
.dropdown-content {
  position: absolute;
  margin-top: 16px;
  transform: translateX(-80%);
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 3%;
  color: var(--A-azul, #035e7b);
}
.dropdown-content > a {
  padding: 4px 20px;
  text-decoration: none;
  display: block;
  color: var(--A-azul, #035e7b);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.dropdown-content > a:hover {
  background: var(--Bode, #cedfe5);
  color: var(--A-azul, #035e7b);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
