.price-exchange {
  background: #131a23;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  height: 5vh;
  padding: 0px 50px;
  align-items: center;
  gap: 20px;
}

@media (max-width: 1000px) {
  .price-exchange {
    overflow-x: scroll; /* Habilita el scroll horizontal si la pantalla es menor a 1000px */
  }
}

.price-exchange p {
  margin: 0;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  min-width: 11em;
}
