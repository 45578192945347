.tracking-most-recent-status tr:first-child {
  font-weight: bold;
  font-size: 15px;
}
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tracking-table {
  margin-bottom: 20px !important;
}

input[type='checkbox'],
input[type='radio'] {
  width: 20px !important;
  height: 20px !important;
}

.title-DataTable {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

/* INGRESO MANUAL BUTON */

.btn-ingreso-manual {
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: var(--ccu-celeste, #007dba);
}

/* ----------------------- SPINNER---------------------------- */

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------- SPINNER---------------------------- */

/* ------------------ Subastas ----------------- */

.card-meet-trader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 100%;
  flex-direction: column;
}

.card-meet-trader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border: 3px solid rgb(107, 99, 99);
  width: 90%;
  border-radius: 15px;
  box-shadow: inset;
  -webkit-box-shadow: 10px 0px 33px 6px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 10px 0px 33px 6px rgba(0, 0, 0, 0.26);
  box-shadow: 10px 0px 33px 6px rgba(0, 0, 0, 0.26);
  height: 100%;
}

.button-card-meet-trader {
  padding: 10px;
  margin-bottom: 15px;
  background-color: transparent;
  border-radius: 10px;
}

.button-card-meet-trader:hover {
  background-color: black;
  color: white;
  transition: all 0.5s ease;
}
.container-row-product {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  border-radius: 5%;

  color: black;
}

.container-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;

  overflow-y: auto;
}

.container-img-product {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
}

.container-img-product > img {
  height: 100%;
  width: 100%;
}

.container-details-product {
  flex: 1;
}

.notDisplayScroll > ::-webkit-scrollbar {
  display: none;
}

.container-licitador {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.btn-licitador {
  padding: 20px;
  background-color: transparent;
  border-radius: 30px;
}
.btn-licitador:hover {
  background-color: black;
  color: white;
}

.container-licitador-title {
  text-align: center;
}
/* ------------------ Subastas ----------------- */

/* ------------------ sales-rules-advertising ----------------- */
.sales-rules-advertising {
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  position: relative;
  width: 100%;
}
.sales-rules-advertising span {
  display: inline-flex;
  justify-content: center;
  margin: 1px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.5s;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.sales-rules-advertising .general-rule {
  background-color: rgba(5, 135, 70, 0.627);
}

.sales-rules-advertising .discount-rule {
  background-color: rgba(98, 135, 5, 0.627);
}
/* ------------------ sales-rules-advertising ----------------- */
