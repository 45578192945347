.login-page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
}
.login-form {
  display: grid;
  min-width: 50%;
}

.login-form .title {
  text-align: center;
  color: #fff;
  font-family: Roboto Slab;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.login-form .text-under-title {
  color: var(--Gray-3, #828282);
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.login-form .form-group {
  width: 100%;
}

.login-form .form-group .user-label {
  padding-top: 20px;
  text-align: left;
  color: var(--Gray-3, #828282);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.login-form .form-group .user-input {
  display: flex;
  width: 100%;
  height: 48px;
  padding: 4px 8px 4px 12px;
  justify-content: space-between;
  align-items: center;
  color: var(--A-oscuro, #002e2c);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.login-form .form-group .password-label {
  padding-top: 20px;
  text-align: left;
  color: var(--Gray-3, #828282);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.login-form .form-group .password-input {
  display: flex;
  width: 100%;
  height: 48px;
  padding: 4px 8px 4px 12px;
  justify-content: space-between;
  align-items: center;
  color: var(--A-oscuro, #002e2c);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.login-form .form-group .reset-password {
  color: var(--A-oscuro, #002e2c);
  text-align: right;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-decoration-line: underline;
}

.login-form .form-group .submit-button {
  text-align: center;
  padding-top: 20px;
}

.login-form .form-group .submit-button button {
  padding-top: 20px;
  display: flex;
  width: 100%;
  height: 48px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px;
  background: var(--A-amarillo, #ffc857);
  color: var(--A-oscuro, #002e2c);
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.login-form .form-group .submit-button button:hover {
  background: #ffac01;
}

.login-form > .sign-up-container {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form > .sign-up-container > hr {
  width: 100%;
  border: 1px solid #fff;
}
.login-form > .sign-up-container > button {
  padding-top: 20px;
  display: flex;
  width: 70%;
  height: 48px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px;
  background: #42b72a;
  color: #fff;
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.login-form > .sign-up-container > button:hover {
  background: #359322;
}

/* ----------------------- SPINNER---------------------------- */

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--A-amarillo, #ffc857);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------- SPINNER---------------------------- */
