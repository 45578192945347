.product-store-page-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 80vh;
  width: 100%;
  padding: 10px 150px;
}

.product-store-page-container > .tab-container {
  display: flex;
  max-width: 450px;
  justify-content: space-between;
}
.product-store-page-container > .tab-container > span {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: solid 3px #ffd60a00;
}
.product-store-page-container > .tab-container > span > p {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f2f2f2;
}
.product-store-page-container > .tab-container > .active-tab {
  border-bottom: solid 3px #ffd60a;
}
.product-store-page-container > .data-table {
  width: 100%;
  overflow: auto;
}

.product-store-page-container > .data-table > .loading-table-text {
  display: flex;
  justify-content: center;
  color: #fff;
}
.product-store-page-container > .data-table > table {
  border-collapse: collapse;
  width: 100%;
  color: #fff;
}
.product-store-page-container > .data-table > table th {
  border: none;
}
.product-store-page-container > .data-table > table td {
  border: none;
  border-top: solid 1px rgba(255, 255, 255, 0.2);
}
.product-store-page-container > .data-table > table td {
  /* padding: 10px; */
}
.product-store-page-container > .data-table > table td.first-in-row {
  padding: 0 0 0 10px;
}
.product-store-page-container > .data-table > table .col-type-product-name {
  background-color: #131a23;
  text-align: left;
}

.product-store-page-container > .data-table > table .external-company-title {
  flex-grow: 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  padding: 5px 0;
}

.product-store-page-container > .data-table > table .col-type-1 {
  background-color: #131a23;
  text-align: center;
}

.product-store-page-container > .data-table > table td.price-col {
  border-left: solid 1px rgba(255, 255, 255, 0.2);
}

.product-store-page-container > .data-table > table td.qty-col {
  border-left: solid 1px rgba(255, 255, 255, 1);
}

.product-store-page-container > .data-table > table .col-type-2 {
  background-color: #000a18;
  text-align: center;
}

.product-store-page-container > .data-table > table > tbody td > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.product-store-page-container > .data-table > table > tbody td > div > p {
  margin: 0 0 0 20px;
}

.product-store-page-container > .data-table > table > tbody td > div > .add-icon-container {
  width: 20px;
}

.product-store-page-container
  > .data-table
  > table
  > tbody
  td
  > div
  > .add-icon-container
  > div
  > .add-icon {
  cursor: pointer;
}

.price-input-modal-container {
  background-color: #000a18;
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.35);
  border: 2px solid #1b1717;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.price-input-modal-title {
  display: flex;
  flex-direction: column;
}
.price-input-modal-title > h2 {
  margin: 0;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}
.price-input-modal-title > h1 {
  margin: 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.price-input-modal-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.price-input-modal-left > .price-input-modal-description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.price-input-modal-left > .price-input-modal-description > p {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
}
.price-input-modal-left > .price-input-modal-description > p > span {
  font-size: 16px;
  font-weight: bold;
}
.price-input-modal-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 2%;
}
.price-input-modal-right .price-input-modal-right-price-text {
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
.price-input-modal-right .price-input-modal-right-price-text .price-without-discount {
  color: #999999;
  font-size: 18px;
}
.price-input-modal-right .price-input-modal-right-price-text .price-with-discount {
  color: #00f947;
}
.price-input-modal-right .price-input-modal-right-price-text > p {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin: 0;
}

.price-input-modal-right .price-input-modal-right-add-cart-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.price-input-modal-right .price-input-modal-right-quantity-input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  gap: 10px;
}
.price-input-modal-right .price-input-modal-right-quantity-input button {
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  border: none;
  border-radius: 5px;
}
.price-input-modal-right .price-input-modal-right-quantity-input button:hover {
  border: 2px solid #ffac01;
}

.price-input-modal-right .price-input-modal-right-quantity-input .input-quantity {
  font-size: 20px;
  width: 30px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: #fff;
}

/* remove number input arrows (up-down) */
.price-input-modal-right
  .price-input-modal-right-quantity-input
  .input-quantity::-webkit-outer-spin-button,
.input-quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.price-input-modal-right .price-input-modal-right-quantity-input > .input-quantity {
  -moz-appearance: textfield;
}

.price-input-modal-right-add-cart-container > .add-to-cart-button {
  width: 100%;
  height: 48px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0px;
  background-color: #ffc857;
  color: #002e2c;
  text-align: center;
  font-feature-settings:
    'cv11' on,
    'cv01' on,
    'ss01' on;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.price-input-modal-right-add-cart-container > .add-to-cart-button:hover {
  background-color: #ffac01;
}

.store-advicement {
  background-color: #131a23;
  color: white;
  padding: 2em;
}
.store-advicement h1 {
  text-align: center;
}
.store-advicement h2 {
  text-align: center;
}

.store-advicement span {
  display: block;
  text-align: center;
}
/* ----------------------- SPINNER---------------------------- */

.loader {
  width: 34px;
  height: 34px;
  border: 5px solid var(--A-amarillo, #ffc857);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------- SPINNER---------------------------- */

/* Table */
tbody tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 2; /* Asegura que la primera columna esté por encima del contenido */
}
thead {
  position: sticky;
  top: 0;
  z-index: 1; /* Asegura que la cabecera esté por encima del contenido */
}

/* Mobile */
@media (width <= 1200px) {
  .product-store-page-container {
    width: 100%;
    padding: 0;
  }
  .price-table {
    overflow-x: scroll;
  }
}
