.top-container-info-chart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.historical-price-chart-dropdown {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.historical-price-chart-dropdown .ui.fluid.dropdown {
  background-color: rgb(241, 186, 10);
  color: #000;
}
.historical-price-chart-dropdown .ui.fluid.dropdown.active {
  background-color: rgb(241, 186, 10);
  color: #000;
}

.historical-price-chart {
  display: flex;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.div-ul-inline {
  width: 300px;
}

.ul-inline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1 em;
  color: #5c6571;
  font-weight: 700;
}

.ul-inline li {
  display: inline;
}

.ul-inline li:hover {
  color: white;
  cursor: pointer;
}

.price-container {
  padding-top: 0.5em;
}

.big-current-price {
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: 700;
  font-size: 50px;
  margin: 0;
}

.small-initial-price {
  display: flex;
  justify-content: flex-end;
  color: white;
  font-weight: 700;
  font-size: 30;
}

.currency {
  padding-left: 0.5em;
  color: white;
  font-size: 20px;
}

.price-percent-diff {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}

.price-percent-diff > .percent {
  color: #0ecd82;
  font-size: 2em;
}

.price-percent-diff > .period {
  color: #535b67;
  padding-left: 0.5em;
}
