.shopping-cart-container {
  display: flex;
  justify-content: flex-end;
  color: white;
}
.shopping-cart-container div {
  display: flex;
  flex-direction: row;
}
.shopping-cart-container .shopping-cart-with-items {
  position: relative;
  margin-right: 0.5em;
}

.shopping-cart-container .shopping-cart-with-items .truck-icon {
  margin-top: 2px;
}
.shopping-cart-container .shoppingCartCounter {
  display: flex;
  background: var(--A-amarillo, #b52a00);
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  position: absolute;
  top: -25%;
  right: -40%;
}
