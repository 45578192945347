.home-control-panel-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-panel-item {
  margin-inline: 5%;
  border-radius: 10px;
  border: 2px solid var(--Gray-5, #e0e0e0);
  width: 35%;
  background-color: white;
}

.control-panel-item:hover {
  background: rgba(255, 200, 87);
  cursor: pointer;
  border: 2px solid #848484;
}

.control-panel-item .item-container {
  padding: 50px 20px 50px 20px;
  text-align: center;
}
.item-container .item-title {
  padding-bottom: 50px;
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto Slab;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.item-container .item-title span {
  color: var(--Gray-2, #4f4f4f);
  font-family: Roboto Slab;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.item-container .item-icon img {
  width: 100%;
  height: 100%;
}

.item-container .item-description {
  padding-top: 50px;
  color: var(--black-100, var(--black-100, #1c1c1c));
  text-align: center;
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
