.generic-dropdown-menu-container {
  height: 100%;
  width: 100%;
}

.generic-dropdown-menu-container > ul {
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
}
.generic-dropdown-menu-container-child > ul {
  padding: 2% 0%;
}

.generic-dropdown-menu-container > ul > li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.generic-dropdown-menu-container > ul > li .generic-dropdown-menu-item {
  cursor: pointer;
  width: 100%;
  padding: 2%;
  display: flex;
  align-items: center;
  border-left-style: solid;
  border-left-width: 4px;
  border-color: #ffffff00;
}
.generic-dropdown-menu-item-child > p {
  padding-left: 20px;
}

.generic-dropdown-menu-item-opened {
  border-radius: 8px;
  background-color: #e0e0e0;
}

.generic-dropdown-menu-item .generic-dropdown-menu-item-label {
  margin-left: 4px;
  color: var(--A-oscuro, #002e2c);
  font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
